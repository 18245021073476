import React from "react";
import { useLocation } from "react-router-dom";
import "./NewsDetails.css";
import Footer from "../Footer/Footer";

const NewsDetail = () => {
  const { state } = useLocation();
  const article = state ? state.article : null;

  const getYouTubeEmbedUrl = (url) => {
    let match =
      url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/) ||
      url.match(/youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
  };

  if (!article) {
    return <p style={{ textAlign: "center" }}>Loading...</p>;
  }

  const videoUrl = getYouTubeEmbedUrl(article.content);

  return (
    <>
      {article.image && (
        <div className="news-image-container">
          <img src={article.image} alt="news-item" className="news-image" />
        </div>
      )}
      <div className="news-content">
        <h1>{article.headline}</h1>
        {article.content
          .replace(/\\n/g, "\n")
          .split("\n")
          .map((line, index) => (
            <p key={index}>{line}</p>
          ))}

        {videoUrl && (
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src={videoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default NewsDetail;
